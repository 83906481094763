import React from 'react'
import { Link } from 'react-router-dom'
import john_headshot from '../images/john_headshot.jpg'

const Team = () => {
  return (
    <>
      <div className="my-4 py-4" id="team">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Team</h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-1 lg:grid-cols-1 gap-5 max-w-xl m-auto">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Dr. John Lees, CEO and Founder
                </h4>
                <img alt="John Lees" className="rounded-full mx-auto mb-4" src={john_headshot} />

                <p className="text-md font-medium leading-5 h-auto md:h-48 text-center">
                  Dr. John Lees has more than 20 years of experience in healthcare technology in a
                  wide variety of roles. From strategy and management consulting to leading large
                  organizations to founding other successful healthcare companies, John creates
                  value in every venture. Stemming from his Ph.D. in Physics, he takes a deep
                  analytical look at solving deep-rooted and complex problems. He has long had a
                  passion to create transparency in healthcare—to bring efficiency where there is
                  none. Copay Technologies aims to do just that—allow people to make informed
                  decisions about where they go to seek care and put downward pressure on costs
                  while also improving outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team
